/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Autoservis"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--50 pt--60" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--72" content={"Autoservis všech značek osobních i dodávkových vozidel včetně karavanů"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"usk9zs6vftp"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":650}} content={"zabýváme se opravou vozů všech značek\nOpravujeme veškeré osobní i dodávkové vozidla včetně obytných vozidel a karavanů&nbsp;<br>&nbsp;Karosářské práce - Pojistné události -&nbsp; Příprava provedení STK a ME - Výměna autoskel z povinného ručení -&nbsp; Odtahová služba - Plnění a údržba klimatitací -&nbsp; Pneuservis - Laserová geometrie&nbsp;<br>&nbsp;Autoservis\nOpravy naftových i benzínových vozidel\nVýměna brzd, obložení\nVýměna oleje\nRepas náprav\nVýměna výfukových systémů\nOpravy turbodmychadel"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ew5ns3dj6c"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--30 pt--30" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Laserová geometrie"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Měření a seřízení geometrie na osobních i užitkových vozidlech.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Výměna autoskel z povinného ručení"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Zdarma vám vyměníme sklo na vašem vozidle. Vše na pojišťovně vyřešíme za vás."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Pojistné události"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Vyřešíme pojistnou událost včetně administrativních úkonů. Zdarma vyřešíme veškeré náležitosti kolem pojistek a pojistných událostí. Zapůjčení vozidla zdarma."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box" content={"Karosářské práce&nbsp;"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"&nbsp;Opravy vozů po nehodách, bouračkách. Veškeré karosářské práce na vozidlech.<br>výměna prahů<br>výměna lemů&nbsp;<br>výměna karosářských dílů&nbsp;<br>Rovnání karoserii&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box" content={"Autoservis"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Opravy naftových i benzínových vozidel\nVýměna brzd, obložení\nVýměna oleje\nRepas náprav\nVýměna výfukových systémů\nOpravy turbodmychadel"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box" content={"Ražba VIN kódů"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"&nbsp;Provádíme ražbu VIN kódů náhradní technologií. Informujte se u nás."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"bicv3rqjiwd"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--30 pt--30" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Diagnostika"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Diagnostika na všechny typy vozidel.."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Měření akmulátorů a alternátorů"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Proměření baterie a alternátorů na počkání."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Pneuservis"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Prodej letních i zimních pneumatik. Výměna všech typů a rozměrů pneu"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box" content={"Klimatizace"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"&nbsp;Provádíme kompletní servis, plnění a údržbu klimatizací do všech typů vozidel. Desinfekce interiéru ozonem"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box" content={"Tažná zařízení&nbsp;"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Zajistíme prodej a montáž tažného zařízení pro všechny typy vozidel."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obsah-1"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1odcdv6 css-42e4bw pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Máte dotaz?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22" url={"https://autoservisblaza.cz/kontaktni-formular/"} href={"https://autoservisblaza.cz/kontaktni-formular/"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1--95);\">Autoservis Bláža Ostrava - Radvanice&nbsp;</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-1--95);\">Pro Vaše auto uděláme vše, co budeme moct.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-1--95);\">Jsme Malý a nezávislý autoservis osobních i užitkových vozidel. Měření a seřízení geometrie kol Servis čištění a plnění klimatizací Rychloservis Pneuservis Nástřiky podvozku a dutin Strojní regenerace filtru pevných částic DPF a jiné.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-custom-1--95);\">&nbsp;Lihovarská 1378/11, Ostrava - Radvanice Areál Bánských strojíren ( BASTRO )\nTel: +420 777 321 587&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">IČ: 63471710</span><span style=\"color: white;\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}